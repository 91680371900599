// TODO: delete this file
// react modules
import React, { useState, useRef } from "react";

// third-party modules
import { Button, FormBuilder } from "@onehq/anton";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";

// app modules
import ListFormFields from "../../pages/lists/sections/ListFormFields";
import {
  ListInput,
  CreateListMutationResult,
  UpdateListMutationResult
} from "../../generated/graphql";
import { GenericFormProps } from "../../types";
import { DiscardFormModal } from "../../contexts/DiscardFormModal";
import { showErrorMessage } from "../../utils/notifications";
import ButtonGroup from "../Form/ButtonGroup";

export interface ListFormProps extends GenericFormProps<ListInput> {
  formType?: string;
  setLoadList?: React.Dispatch<React.SetStateAction<boolean>>;
  onSave?: () => void;
  onDiscard?: () => void;
}

const ListForm = (props: ListFormProps) => {
  const alert = useDispatchGrowlContext();
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  const values = props.values;
  const refForm = useRef<HTMLFormElement>(null);
  const isEdit = !!(values && values.id);
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  return (
    <FormBuilder
      ref={refForm}
      autosave={isEdit}
      values={{ ...values }}
      type="dialogForm"
      // prettier-ignore
      // eslint-disable-next-line
      onSubmit={async ({...attr}, { setError }) => {
        alert({
          type: ADD,
          payload: {
            id: idForAlert,
            title: isEdit ? "Saving list..." : "Creating list...",
            variant: "progress",
            indeterminate: true
          }
        });
        if (isEdit) {
          props.mutation({ variables: { id: attr.id, attributes: attr }}).then(({ data }: UpdateListMutationResult) => {
            alert({
              type: REMOVE,
              payload: {
                id: idForAlert,
                title: "",
                variant: "success"
              }
            });
            const errors = data?.updateList?.errors;
            if (errors && Object.keys(errors).length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              showErrorMessage(errors, setError);
            } else {
              props.onSave && props.onSave();
            }
          });
        } else {
          props
            .mutation({ variables: { attributes: attr } })
            .then(({ data }: CreateListMutationResult) => {
              alert({
                type: REMOVE,
                payload: {
                  id: idForAlert,
                  title: "",
                  variant: "success"
                }
              });
              const errors = data?.createList?.errors;
              if (errors && Object.keys(errors).length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                showErrorMessage(errors, setError);
              } else {
                props.onSave && props.onSave();
              }
            })
            .catch(e => console.log(e));
        }
      }}
    >
      <ListFormFields />

      {!isEdit && (
        <ButtonGroup>
          {props.onDiscard && (
            <DiscardFormModal resource="List" onDiscard={props.onDiscard}>
              <Button variant={"secondary"}> Discard </Button>
            </DiscardFormModal>
          )}
          {props.setLoadList && (
            <Button
              onClick={() => {
                props.setLoadList && props.setLoadList(true);
                onSubmit();
              }}
            >
              Save and load
            </Button>
          )}
          <Button onClick={onSubmit}> Save </Button>
        </ButtonGroup>
      )}
    </FormBuilder>
  );
};

export default ListForm;
