import React from "react";
import Userfront from "@userfront/core";
import {
  CommunicationWindow,
  Float,
  Variants as FloatVariants
} from "@onehq/anton";
import {
  FrameworkApp,
  convertIntrospectionToPossibleTypesMap,
  buildApolloClient
} from "@onehq/framework";
// app modules
import {
  useCreateCampaignMutation,
  useCreateClientMutation,
  useCreateListMutation,
  useCreatePhoneMutation,
  useCreateTeamMutation
} from "./generated/graphql";
import fragmentTypes from "./generated/fragmentTypes";
import appNavLinks from "./appNavLinks";
import { privateRoutes, publicRoutes } from "./routes";
import ClientForm from "./components/Client/ClientForm";
import PhoneForm from "./components/Phone/PhoneForm";
import ListForm from "./components/List/ListForm";
import TeamForm from "./pages/teams/sections/TeamGeneralForm";
import CampaignForm from "./components/Campaign/CampaignForm";
import FloatingForm from "./components/Form/FloatingForm";
import Work from "./components/Work";
import Logo from "./assets/imgs/layout-logo.png";

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-floating-promises
const initializeUserfront = async () => {
  const userfrontTenantId = process.env.REACT_APP_USERFRONT_TENANT_ID;

  if (
    typeof userfrontTenantId !== "string" ||
    userfrontTenantId.trim() === ""
  ) {
    throw new Error("REACT_APP_USERFRONT_TENANT_ID is not defined or invalid.");
  }

  // Initialize Userfront with the tenant ID
  await Userfront.init(userfrontTenantId);
};

initializeUserfront().catch(error => {
  console.error("Failed to initialize Userfront:", error);
});

export const accessToken = Userfront.tokens.accessToken;

export const apollo = buildApolloClient({
  options: {
    fragmentTypes: convertIntrospectionToPossibleTypesMap(fragmentTypes),
    uri: process.env.REACT_APP_BACKEND_END_POINT,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }
});

export const globalConfigs: FloatVariants = {
  Client: {
    form: ClientForm,
    mutation: useCreateClientMutation,
    root: FloatingForm
  },
  Phone: {
    form: PhoneForm,
    mutation: useCreatePhoneMutation,
    root: FloatingForm
  },
  List: {
    form: ListForm,
    mutation: useCreateListMutation,
    root: FloatingForm
  },
  Team: {
    form: TeamForm,
    mutation: useCreateTeamMutation,
    root: FloatingForm
  },
  Campaign: {
    form: CampaignForm,
    mutation: useCreateCampaignMutation,
    root: FloatingForm
  },
  Work: {
    root: CommunicationWindow
  }
};

const Footer = (
  <div>
    <Float globalConfigs={globalConfigs} />
    <Work />
  </div>
);

const App = () => {
  // TODO: fix apollo configuration
  // FrameworkApp includes the ApolloClient provider
  // so we must manually pass in the client to the currentUser query
  // const { data } = useCustomCurrentUserQuery({ client: client });

  const getAccessToken = () => {
    const auth = accessToken;

    return !!auth;
  };

  return (
    <FrameworkApp
      apollo={apollo}
      linkSections={appNavLinks}
      logo={Logo}
      isAuthenticated={getAccessToken}
      publicRoutes={publicRoutes}
      routes={privateRoutes}
      customBaseRedirect={"/dashboard"}
      footer={Footer}
    />
  );
};

export default App;
