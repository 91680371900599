/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Table } from "@onehq/anton";
import { CollectionListFieldsFragment } from "../../generated/graphql";

import { BaseTableProps } from "../../types";
import { Link } from "@onehq/framework";
import { COLLECTIONS_PATH } from "../../constants/collectionConstants";
import { formatDate } from "../../utils";

export type CollectionTableProps = BaseTableProps<CollectionListFieldsFragment>;

export const CollectionTableColumns = [
  {
    id: "collectionType",
    header: "Collection Type",
    accessorKey: "collectionType"
  },
  {
    id: "amount",
    header: "Amount",
    accessorKey: "amount",
    type: "number"
  },
  {
    id: "fee",
    header: "Fee",
    accessorKey: "fee",
    type: "number"
  },
  {
    id: "collectionDate",
    header: "Collection Date",
    accessorKey: "collectionDate"
  }
];

export const collectionTableDataFormatter = (
  data?: Array<CollectionListFieldsFragment>
) => [
  ...(data?.map((item: CollectionListFieldsFragment) => {
    const { id, collectionType, amount, fee, collectionDate } = item;

    return {
      collectionType: id ? (
        <Link to={`/${COLLECTIONS_PATH}/${id}/overview`}>{collectionType}</Link>
      ) : (
        collectionType
      ),
      amount,
      fee: fee ? fee : "-",
      collectionDate: formatDate(collectionDate)
    };
  }) || [])
];

const CollectionTable = ({ data = [], ...props }: CollectionTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={CollectionTableColumns}
      data={collectionTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default CollectionTable;
