import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DocumentNode, QueryResult, OperationVariables } from "@apollo/client";
import {
  actionGroupFormat,
  ActionGroupProps,
  ContentHeaderProps,
  TableProps
} from "@onehq/anton";
import { BaseResource, useIsMobile } from "@onehq/framework";

import {
  GetClientsListCsvDocument,
  GetListsListCsvDocument,
  GetTextsListCsvDocument,
  GetProjectsListCsvDocument,
  GetTeamsListCsvDocument,
  GetUsersListCsvDocument,
  GetPhonesListCsvDocument,
  useGetClientsListQuery,
  useGetListsListQuery,
  useGetProjectsListQuery,
  useGetTeamsListQuery,
  useGetUsersListQuery,
  useGetPhonesListQuery,
  useGetTextsListQuery,
  GetTextDefaultsListCsvDocument,
  useGetTextDefaultsListQuery,
  GetCampaignsListCsvDocument,
  useGetCampaignsListQuery,
  GetBrandsListCsvDocument,
  useGetBrandsListQuery,
  GetShortenedLinksListCsvDocument,
  useGetShortenedLinksListQuery,
  GetCollectionsListCsvDocument,
  useGetCollectionsListQuery
} from "../../generated/graphql";
import { ListTableColumns, listTableDataFormatter } from "../List/ListTable";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../Text/TextTable";
import {
  TextDefaultTableColumns,
  textDefaultTableDataFormatter
} from "../TextDefault/TextDefaultTable";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../Project/ProjectTable";
import { UserTableColumns, userTableDataFormatter } from "../User/UserTable";
import {
  ClientTableColumns,
  clientTableDataFormatter
} from "../Client/ClientTable";
import {
  PhoneTableColumns,
  phoneTableDataFormatter
} from "../Phone/PhoneTable";
import { TeamTableColumns, teamTableDataFormatter } from "../Team/TeamTable";
import { useGenerateCsv } from "../generateCsv";
import { PAGE_SIZE, addSpacesBetweenWords } from "../../utils/helperFunctions";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { GoBack } from "../../routes";
import { STEPPER_PATH } from "../../constants";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../Campaign/CampaignTable";
import {
  BrandTableColumns,
  brandTableDataFormatter
} from "../Brand/BrandTable";
import {
  ShortenedLinkTableColumns,
  shortenedLinkTableDataFormatter
} from "../ShortenedLink/ShortenedLinkTable";
import { camelCase, kebabCase } from "lodash";
import {
  CollectionTableColumns,
  collectionTableDataFormatter
} from "../Collection/CollectionTable";

type Resources =
  | "Brands"
  | "Campaigns"
  | "Clients"
  | "Lists"
  | "Phones"
  | "Projects"
  | "ShortenedLinks"
  | "Teams"
  | "Texts"
  | "TextDefaults"
  | "Collections"
  | "Users";

interface ResourceImport {
  getListDocument: DocumentNode;
  getListQuery: Function;
  columns: TableProps["columns"];
  formatData: (data: Array<any>) => Array<any>;
}

const resourceImports: Record<Resources, ResourceImport> = {
  Brands: {
    getListDocument: GetBrandsListCsvDocument,
    getListQuery: useGetBrandsListQuery,
    columns: BrandTableColumns,
    formatData: brandTableDataFormatter
  },
  Campaigns: {
    getListDocument: GetCampaignsListCsvDocument,
    getListQuery: useGetCampaignsListQuery,
    columns: CampaignTableColumns,
    formatData: campaignTableDataFormatter
  },
  Clients: {
    getListDocument: GetClientsListCsvDocument,
    getListQuery: useGetClientsListQuery,
    columns: ClientTableColumns,
    formatData: clientTableDataFormatter
  },
  Lists: {
    getListDocument: GetListsListCsvDocument,
    getListQuery: useGetListsListQuery,
    columns: ListTableColumns,
    formatData: listTableDataFormatter
  },
  Projects: {
    getListDocument: GetProjectsListCsvDocument,
    getListQuery: useGetProjectsListQuery,
    columns: ProjectTableColumns,
    formatData: projectTableDataFormatter
  },
  Teams: {
    getListDocument: GetTeamsListCsvDocument,
    getListQuery: useGetTeamsListQuery,
    columns: TeamTableColumns,
    formatData: teamTableDataFormatter
  },
  Phones: {
    getListDocument: GetPhonesListCsvDocument,
    getListQuery: useGetPhonesListQuery,
    columns: PhoneTableColumns,
    formatData: phoneTableDataFormatter
  },
  ShortenedLinks: {
    getListDocument: GetShortenedLinksListCsvDocument,
    getListQuery: useGetShortenedLinksListQuery,
    columns: ShortenedLinkTableColumns,
    formatData: shortenedLinkTableDataFormatter
  },
  Texts: {
    getListDocument: GetTextsListCsvDocument,
    getListQuery: useGetTextsListQuery,
    columns: getTextTableColumns(),
    formatData: useTextTableDataFormatter
  },
  TextDefaults: {
    getListDocument: GetTextDefaultsListCsvDocument,
    getListQuery: useGetTextDefaultsListQuery,
    columns: TextDefaultTableColumns,
    formatData: textDefaultTableDataFormatter
  },
  Collections: {
    getListDocument: GetCollectionsListCsvDocument,
    getListQuery: useGetCollectionsListQuery,
    columns: CollectionTableColumns,
    formatData: collectionTableDataFormatter
  },
  Users: {
    getListDocument: GetUsersListCsvDocument,
    getListQuery: useGetUsersListQuery,
    columns: UserTableColumns,
    formatData: userTableDataFormatter
  }
};

export interface ListPageProps {
  variant: Resources;
}

const ListPage = ({ variant }: ListPageProps) => {
  useDocumentTitle(variant);
  const titleLowerCase = variant.toLowerCase();
  const titleKey = camelCase(variant);
  const navigateTo = useNavigate();

  const [filterOptions, setFilterOptions] = useState({ first: PAGE_SIZE });

  const isMobile = useIsMobile();

  const { getListDocument, getListQuery, columns, formatData } =
    resourceImports[variant];

  const { data, error, loading, refetch }: QueryResult = getListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      first: PAGE_SIZE
    }
  });

  const handleRefetch = (options: Partial<OperationVariables>) => {
    setFilterOptions(prev => ({
      ...prev,
      ...options
    }));
    void refetch(options);
  };

  const nodes = (data?.[titleKey].nodes as any[]) || [];

  // @ts-ignore
  const [actions, setActions] = useState<ContentHeaderProps["actions"]>(<></>);
  const { csvButtonProps } = useGenerateCsv({
    disable: !nodes.length,
    apolloDocument: getListDocument,
    variables: filterOptions
  });

  const onAddNew = () => {
    const link =
      variant === "Projects"
        ? `/${STEPPER_PATH}/`
        : `/${kebabCase(titleKey)}/new`;

    navigateTo(link);
  };

  useEffect(() => {
    const tempActions: ActionGroupProps["actions"] = [
      {
        type: "button",
        name: "Add",
        actionProps: {
          variant: "secondary",
          icon: "plus",
          iconPosition: "leading",
          onClick: onAddNew
        }
      },
      {
        type: "button",
        name: "Export",
        actionProps: {
          ...csvButtonProps
        }
      }
    ];

    setActions(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      actionGroupFormat({ actions: tempActions, mobile: isMobile }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, nodes.length, filterOptions]);

  if (error) {
    return <GoBack />;
  }

  return (
    <BaseResource.List
      title={addSpacesBetweenWords(variant)}
      data={{
        ...data,
        [titleLowerCase]: {
          ...data?.[titleKey],
          nodes: formatData(nodes)
        }
      }}
      fixedHeader
      loading={loading}
      refetchQuery={handleRefetch}
      columns={columns}
      contentHeaderProps={{
        title: addSpacesBetweenWords(variant),
        actions
      }}
    />
  );
};

export default ListPage;
