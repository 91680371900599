import React from "react";
import OptInPage from "../pages/opts/Template1/OptIn";
import OptInTerms from "../pages/opts/Template1/OptInTerms";
import OptInPrivacy from "../pages/opts/Template1/OptInPrivacy";
import { OptInData } from "../pages/opts/content";
import { UseDocumentHead } from "./useDocumentHead";
import { OptInContentItem } from "../pages/opts";

export function redirectToOptPages() {
  const currentUrl = window.location.href;
  const currentOptName = currentUrl.split(".")[1];
  const currentOptResource = currentUrl.split("/").pop() || "opt-in";

  const optContent: OptInContentItem = OptInData[currentOptName];

  if (!optContent) {
    return null;
  }

  // TODO: Remove eslint-disable once the implementation is corrected
  // eslint-disable-next-line new-cap
  UseDocumentHead({
    title: optContent.form.clientName,
    favicon: optContent.icon,
    appleIcon: optContent.appleIcon
  });

  switch (currentOptResource) {
    case "opt-in":
      return <OptInPage />;
    case "terms":
      return <OptInTerms />;
    case "privacy":
      return <OptInPrivacy />;
    default:
      return null;
  }
}

export function isOptPage() {
  const currentUrl = window.location.href;
  const appBaseUrl = process.env.REACT_APP_PUBLIC_URL;

  return currentUrl !== appBaseUrl && Boolean(redirectToOptPages());
}
