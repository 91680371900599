/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  Fieldset,
  useReset,
  useWatchContext,
  MultiSwitchField
} from "@onehq/anton";
import { useParams } from "react-router-dom";

// app modules
import ProjectPhonesFields from "./ProjectPhonesFields";
import {
  addSpacesBetweenWords,
  formatCampaignOption,
  formatPhoneOption
} from "../../../utils";
import {
  Campaign,
  CampaignQueryFilterFields,
  Phone as PhoneData,
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneStatus,
  ProjectAreaCodesQuery,
  ProjectStatus,
  SendingType,
  useGetCampaignsListLazyQuery,
  useGetPhonesListLazyQuery,
  useProjectAreaCodesQuery,
  CampaignType,
  useGetCampaignsListQuery
} from "../../../generated/graphql";
import { campaignTypeOptions } from "../../campaigns/sections";

const pendingPhoneStatuses = [
  PhoneStatus.Pending,
  PhoneStatus.AwaitingApiVerify,
  PhoneStatus.AwaitingMmsVerify,
  PhoneStatus.AwaitingSmsVerify
].map(s => `PhoneStatus::::${addSpacesBetweenWords(s)}`);

export const sendingTypeOptions = Object.values(SendingType)
  .reverse()
  .map(s => ({
    label: addSpacesBetweenWords(SendingType[s]),
    value: s
  }));

const PREFIX = "projectPhonesAttributes";

export type Phone = Partial<
  Pick<PhoneData, "id" | "number" | "description" | "campaignId"> & {
    campaign: Partial<Campaign>;
  }
>;

const ProjectCampaignsForm = () => {
  const params = useParams();
  const projectId = params.id || "";

  const [lastCursor, setLastCursor] = useState<string>();
  const [retrievedAreaCodes, setRetrievedAreaCodes] = useState<
    NonNullable<ProjectAreaCodesQuery["projectAreaCodes"]["edges"]>
  >([]);
  const [currentList, setCurrentList] = useState<string>();
  const [campaignsWithOrg, setCampaignsWithOrg] = useState<Campaign[]>([]);
  // all the phones for all providers in the project
  const [allPhones, setAllPhones] = useState<Phone[]>([]);
  const [pendingPhones, setPendingPhones] = useState<any[]>([]);

  // hooks
  const listId = useWatchContext("list")?.id;
  const clientId = useWatchContext("clientId")?.value;
  const projectPhones = useWatchContext("projectPhones");
  const projectPhonesAttributes = useWatchContext(PREFIX);
  const projectStatus = useWatchContext("projectStatus");
  const campaignType = useWatchContext("campaignType");
  const reset = useReset();

  const isComplete = useMemo(() => {
    return projectStatus === ProjectStatus.Complete;
  }, [projectStatus]);

  // queries
  // a paginated list of all area codes for the list of the project
  const projectAreaCodes = useProjectAreaCodesQuery({
    fetchPolicy: "network-only",
    variables: { projectId, first: 20 }
  });
  const [getPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const { data: campaignsWithoutOrgData } = useGetCampaignsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: CampaignQueryFilterFields.OrganizationId,
          operation: FilterOperation.With,
          value: "false"
        }
      ]
    }
  });
  const [getPendingPhones] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const campaignsWithoutOrg = useMemo(() => {
    const nodes = campaignsWithoutOrgData?.campaigns.nodes || [];
    return nodes as Campaign[];
  }, [campaignsWithoutOrgData]);

  const allCampaigns = useMemo(() => {
    if (campaignType === CampaignType.Registered) return [...campaignsWithOrg];
    else return [...campaignsWithoutOrg, ...campaignsWithOrg];
  }, [campaignsWithOrg, campaignsWithoutOrg, campaignType]);

  useEffect(() => {
    getPendingPhones({
      variables: {
        filters: [
          {
            field: PhoneQueryFilterFields.PhoneStatusId,
            operation: FilterOperation.In,
            arrayValues: pendingPhoneStatuses
          }
        ]
      }
    })
      .then(response => {
        const phones = response.data?.phones.nodes;
        setPendingPhones(phones || []);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    // new edges for the same list OR new edges of another list
    const newData = projectAreaCodes.data?.projectAreaCodes;
    const newRows = newData?.edges || [];
    const newRowsIsPage1 = !newData?.pageInfo.hasPreviousPage;
    // if the old rows are already set, we set the new pages in the nested form;
    // so this setter is used only once*, the first time.
    if (newRowsIsPage1) setRetrievedAreaCodes(newRows);
  }, [projectAreaCodes]);

  const defaultAreaCodeData = useMemo(() => {
    // this query give us the skeleton of the table
    // just the area codes & times used doesn't have the campaigns nor phones
    return retrievedAreaCodes.map(n => ({
      areaCode: n?.node?.areaCode || "",
      timesUsed: n?.node?.timesUsed || 0,
      cursor: n?.cursor || ""
    }));
  }, [retrievedAreaCodes]);

  useEffect(() => {
    if (listId !== currentList) setCurrentList(listId as string | undefined);
  }, [listId]);

  useEffect(() => {
    void projectAreaCodes.refetch({ after: "" });
  }, [currentList]);

  // the campaigns can be only from the client or with client=null
  useEffect(() => {
    // if registered, retrieves all registered campaigns of the org
    // if unregistered, retrieves all unregistered campaigns of the org only
    // we fetch unregistered campaigns with no org with another query
    getCampaigns({
      variables: {
        filters: [
          {
            field: CampaignQueryFilterFields.CampaignTypeId,
            operation: FilterOperation.Equal,
            value: `CampaignType::::${campaignType}`
          },
          {
            field: CampaignQueryFilterFields.ClientId,
            operation:
              campaignType === CampaignType.Registered
                ? FilterOperation.Equal
                : FilterOperation.With,
            value: campaignType === CampaignType.Registered ? clientId : "false"
          }
        ]
      }
    })
      .then(response => {
        setCampaignsWithOrg(
          (response.data?.campaigns?.nodes || []) as Campaign[]
        );
      })
      .catch(errors => console.log(errors));
  }, [clientId, campaignType]);

  useEffect(() => {
    // all phones that can be of use in the table
    getPhones({
      variables: {
        filters: [
          {
            field: PhoneQueryFilterFields.CampaignId,
            operation: FilterOperation.In,
            arrayValues: allCampaigns.map(c => c.id)
          },
          {
            field: PhoneQueryFilterFields.PhoneStatusId,
            operation: FilterOperation.Equal,
            value: `PhoneStatus::::${PhoneStatus.Active}`
          }
        ]
      }
    })
      .then(response => {
        const phones = response.data?.phones?.nodes || [];
        setAllPhones([
          ...phones.map(n => ({
            id: n?.id,
            number: n?.number,
            campaign: n?.campaign || undefined,
            campaignId: n?.campaignId,
            providerColor: n?.providerColor,
            brandColor: n?.brandColor
          }))
        ]);
      })
      .catch(errors => console.log(errors));
  }, [allCampaigns]);

  // when we get the area codes & the project campaigns
  // we have to merge them, each area code has a row and could have a pair campaign/phone
  useEffect(() => {
    // fix an edgy case: change project resets project phones
    const oldRowsNumber = projectPhonesAttributes?.length || 0;
    const newRowsNumber = defaultAreaCodeData.length;
    // no change
    if (oldRowsNumber === newRowsNumber) return;

    const isRegistered = campaignType === CampaignType.Registered;

    // all the created project phones, each will have a row to match
    const formatRows = defaultAreaCodeData.map(row => {
      const projectPhone = projectPhones.find(
        projectPhone => projectPhone.areaCode === parseInt(row.areaCode, 10)
      );
      const opt = projectPhone && formatPhoneOption(projectPhone.phone);
      if (projectPhone)
        opt.value = {
          id: projectPhone.phone.id,
          campaignId: projectPhone.campaign.id
        };
      // we just merge the row data & the pc data
      const rowData: any = {
        id: projectPhone ? projectPhone.id : undefined,
        cursor: row.cursor,
        areaCode: row.areaCode,
        timesUsed: row.timesUsed,
        phoneData: projectPhone ? opt : undefined
      };
      if (isRegistered) {
        rowData.campaignId = projectPhone
          ? formatCampaignOption(projectPhone.campaign)
          : undefined;
      }
      return rowData;
    });
    // this table format is for the fields inside the table
    // they don't use the table's data, but the formbuilder data
    // each row add a `-{index}` to the end of the column field name
    const tableFormat = formatRows;

    // this sets the values for the table,
    // tbh, this is only useful with customcells,
    // since all form values are invinsible for the table
    // and the table values are invinsible for the fields & form
    // const tableValues = formatRows.map(r => {
    //   const value = { ...r, phoneData: r.phoneData?.value };
    //   if (isRegistered) value.campaignId = r.campaignId?.value;
    //   return value;
    // });
    if (tableFormat.length > 0)
      setLastCursor(tableFormat[tableFormat.length - 1].cursor as string);
    // reset the table data for the formbuilder to show the last changes
    reset(PREFIX, tableFormat, {
      absolutePath: true,
      keepStateOptions: {
        keepIsSubmitted: true,
        keepIsValid: true,
        keepDirty: true,
        keepTouched: true,
        keepSubmitCount: true
      }
    });
  }, [
    defaultAreaCodeData,
    projectPhones,
    projectPhonesAttributes,
    campaignType
  ]);

  // we use a nested form because that was the only way that
  // the usesetfieldvalue & usereset taked our changes
  const ProjectPhonesForm = useMemo(() => {
    return () => (
      <ProjectPhonesFields
        clientId={clientId}
        lastCursor={lastCursor}
        setRetrievedAreaCodes={setRetrievedAreaCodes}
        campaignType={campaignType}
        isCompleted={isComplete}
        prefix={PREFIX}
        refetchQuery={projectAreaCodes.refetch}
        allPhones={allPhones}
        allCampaigns={allCampaigns}
        pendingPhones={pendingPhones}
      />
    );
  }, [
    clientId,
    lastCursor,
    campaignType,
    allCampaigns,
    allPhones,
    pendingPhones
  ]);

  return (
    <>
      <Fieldset>
        <MultiSwitchField
          label="Campaign Type"
          name="campaignType"
          tabs={campaignTypeOptions}
          disabled={isComplete}
        />
        <MultiSwitchField
          label="Sending Type"
          name="sendingType"
          tabs={sendingTypeOptions}
          disabled={isComplete}
          span={12}
        />
      </Fieldset>
      <Fieldset>
        <ProjectPhonesForm />
      </Fieldset>
    </>
  );
};

export default ProjectCampaignsForm;
