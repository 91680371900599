// TODO: delete this file
// react modules
import React, { useState, useRef } from "react";

// third-party modules
import { Button, FormBuilder } from "@onehq/anton";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";

// app modules
import CampaignGeneralForm from "../../pages/campaigns/sections/CampaignGeneralForm";
import {
  CampaignInput,
  CreateCampaignMutationResult,
  UpdateCampaignMutationResult
} from "../../generated/graphql";
import { GenericFormProps } from "../../types";
import { DiscardFormModal } from "../../contexts/DiscardFormModal";
import { showErrorMessage } from "../../utils/notifications";
import ButtonGroup from "../Form/ButtonGroup";

export interface CampaignFormProps extends GenericFormProps<CampaignInput> {
  onSave?: () => void;
  onDiscard?: () => void;
}

const CampaignForm = (props: CampaignFormProps) => {
  const alert = useDispatchGrowlContext();
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  const values = props.values;
  const refForm = useRef<HTMLFormElement>(null);
  const isEdit = !!(values && values.id);
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  return (
    <FormBuilder
      ref={refForm}
      autosave={isEdit}
      values={{ ...values }}
      type="dialogForm"
      // @ts-ignore
      onSubmit={({ nestedClientId, ...attr }, { setError }) => {
        // this form is nested
        attr.clientId = nestedClientId || attr.clientId;
        alert({
          type: ADD,
          payload: {
            id: idForAlert,
            title: isEdit ? "Saving campaign..." : "Creating campaign...",
            variant: "progress",
            indeterminate: true
          }
        });
        if (isEdit) {
          props
            .mutation({ variables: { id: attr.id, attributes: attr } })
            .then(({ data }: UpdateCampaignMutationResult) => {
              alert({
                type: REMOVE,
                payload: {
                  id: idForAlert,
                  title: "",
                  variant: "success"
                }
              });
              const errors = data?.updateCampaign?.errors;
              if (errors && Object.keys(errors).length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                showErrorMessage(errors, setError);
              } else {
                props.onSave && props.onSave();
              }
            });
        } else {
          props
            .mutation({ variables: { attributes: attr } })
            .then(({ data }: CreateCampaignMutationResult) => {
              alert({
                type: REMOVE,
                payload: {
                  id: idForAlert,
                  title: "",
                  variant: "success"
                }
              });
              const errors = data?.createCampaign?.errors;
              if (errors && Object.keys(errors).length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                showErrorMessage(errors, setError);
              } else {
                props.onSave && props.onSave();
              }
            })
            .catch(e => console.log(e));
        }
      }}
    >
      <CampaignGeneralForm nested />

      {!isEdit && (
        <ButtonGroup>
          {props.onDiscard && (
            <DiscardFormModal resource="Campaign" onDiscard={props.onDiscard}>
              <Button variant={"secondary"}> Discard </Button>
            </DiscardFormModal>
          )}
          <Button onClick={onSubmit}> Save </Button>
        </ButtonGroup>
      )}
    </FormBuilder>
  );
};

export default CampaignForm;
