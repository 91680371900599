// react modules
import React from "react";

// third-party modules
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  CollectionType,
  useCreateCollectionMutation,
  useGetCollectionQuery,
  useNewCollectionQuery,
  useUpdateCollectionMutation
} from "../../generated/graphql";

import sections from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import ListPage from "../../components/pages/ListPage";
import CollectionAnchor from "./CollectionAnchor";
import { COLLECTIONS_PATH } from "../../constants/collectionConstants";

const CollectionsRoute = new ResourceRoute({
  name: "Collection",
  path: COLLECTIONS_PATH,
  AnchorComponent: CollectionAnchor,
  IndexComponent: () => <ListPage variant="Collections" />,
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewCollectionQuery, "newCollection"],
    // @ts-ignore
    query: [useGetCollectionQuery, "collection"],
    // @ts-ignore
    create: [useCreateCollectionMutation, "createCollection"],
    // @ts-ignore
    update: [useUpdateCollectionMutation, "updateCollection"],
    normalize: values => {
      const collection = typeof values === "object" ? values : {};

      if (collection.collectionType !== CollectionType.Collection) {
        collection.fee = undefined;
      }

      collection.collectionDate = `${collection.collectionDate}T00:00:01`;

      return { ...collection };
    }
  },
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Collection",
      IndexComponent: () => <DataOverview />
    })
  ]
});

export { CollectionsRoute };
