/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BillingReport as Billing } from "../../generated/graphql";
import { Table, TableProps } from "@onehq/anton";
import { BaseTableProps } from "../../types";
import { toInteger } from "lodash";

export type BillingTableProps = BaseTableProps<Billing>;

export const billingTableColumns: TableProps["columns"] = [
  { header: "Organization Id", accessorKey: "organizationId" },
  { header: "Organization", accessorKey: "organization" },
  { header: "Project Id", accessorKey: "projectId" },
  { header: "Project", accessorKey: "project" },
  { header: "Client Id", accessorKey: "clientId" },
  { header: "Client", accessorKey: "client" },
  { header: "Project Date", accessorKey: "projectDate", dataType: "date" },
  { header: "Campaign Type", accessorKey: "campaignType" },
  { header: "Provider", accessorKey: "provider" },
  { header: "Text Type", accessorKey: "textType" },
  { header: "Ready", accessorKey: "ready", dataType: "number" },
  { header: "Queued", accessorKey: "queued", dataType: "number" },
  { header: "Sent", accessorKey: "sent", dataType: "number" },
  { header: "Delivered", accessorKey: "delivered", dataType: "number" },
  { header: "Clicked", accessorKey: "clicked", dataType: "number" },
  { header: "Expired", accessorKey: "expired", dataType: "number" },
  { header: "Rejected", accessorKey: "rejected", dataType: "number" },
  { header: "Spam", accessorKey: "spam", dataType: "number" },
  { header: "Undelivered", accessorKey: "undelivered", dataType: "number" },
  { header: "Rate Limited", accessorKey: "rateLimited", dataType: "number" },
  { header: "Error", accessorKey: "error", dataType: "number" },
  { header: "Failed", accessorKey: "failed", dataType: "number" },
  { header: "Update Failed", accessorKey: "updateFailed", dataType: "number" },
  { header: "Total Sent", accessorKey: "totalSent", dataType: "number" },
  { header: "Received", accessorKey: "received", dataType: "number" },
  {
    header: "Tot Reg Rec MMS",
    accessorKey: "totalRegisteredReceivedMms",
    dataType: "number"
  },
  {
    header: "Tot Reg Rec SMS",
    accessorKey: "totalRegisteredReceivedSms",
    dataType: "number"
  },
  {
    header: "Tot Reg Sent MMS",
    accessorKey: "totalRegisteredSentMms",
    dataType: "number"
  },
  {
    header: "Tot Reg Sent SMS",
    accessorKey: "totalRegisteredSentSms",
    dataType: "number"
  },
  {
    header: "Tot Unreg Rec MMS",
    accessorKey: "totalUnregisteredReceivedMms",
    dataType: "number"
  },
  {
    header: "Tot Unreg Rec SMS",
    accessorKey: "totalUnregisteredReceivedSms",
    dataType: "number"
  },
  {
    header: "Tot Unreg Sent MMS",
    accessorKey: "totalUnregisteredSentMms",
    dataType: "number"
  },
  {
    header: "Tot Unreg Sent SMS",
    accessorKey: "totalUnregisteredSentSms",
    dataType: "number"
  }
];

export const billingTableDataFormatter = (data?: Array<Billing>) => {
  return [
    ...(data?.map((item: Billing) => {
      return {
        organizationId: item.organizationId,
        organization: item.organization,
        projectId: item.projectId,
        project: item.project,
        clientId: item.clientId,
        client: item.client,
        projectDate: new Date(item.projectDate as string),
        campaignType: item.campaignTypeId?.replace("CampaignType::::", ""),
        provider: item.provider?.replace("Provider::::", ""),
        textType: item.textType,
        ready: toInteger(item.ready),
        queued: toInteger(item.queued),
        sent: toInteger(item.sent),
        delivered: toInteger(item.delivered),
        clicked: toInteger(item.clicked),
        expired: toInteger(item.expired),
        rejected: toInteger(item.rejected),
        spam: toInteger(item.spam),
        undelivered: toInteger(item.undelivered),
        rateLimited: toInteger(item.rateLimited),
        error: toInteger(item.error),
        failed: toInteger(item.failed),
        updateFailed: toInteger(item.updateFailed),
        totalSent: toInteger(item.totalSent),
        received: toInteger(item.received),
        totalRegisteredReceivedMms: item.totalRegisteredReceivedMms,
        totalRegisteredReceivedSms: item.totalRegisteredReceivedSms,
        totalRegisteredSentMms: item.totalRegisteredSentMms,
        totalRegisteredSentSms: item.totalRegisteredSentSms,
        totalUnregisteredReceivedMms: item.totalUnregisteredReceivedMms,
        totalUnregisteredReceivedSms: item.totalUnregisteredReceivedSms,
        totalUnregisteredSentMms: item.totalUnregisteredSentMms,
        totalUnregisteredSentSms: item.totalUnregisteredSentSms
      };
    }) || [])
  ];
};

const BillingTable = ({ data = [], ...props }: BillingTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={billingTableColumns}
      data={billingTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default BillingTable;
