// react modules
import React, { useState, useRef } from "react";

// third-party modules
import { Button, FormBuilder } from "@onehq/anton";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";

// app modules
import {
  CreateTeamMutationResult,
  UpdateTeamMutationResult,
  TeamInput
} from "../../../generated/graphql";
import { GenericFormProps } from "../../../types";
import { DiscardFormModal } from "../../../contexts/DiscardFormModal";
import { showErrorMessage } from "../../../utils/notifications";
import ButtonGroup from "../../../components/Form/ButtonGroup";
import TeamFormFields from "./TeamGeneralFormFields";

export const teamFormOmitValues = [
  "__typename",
  "mediaFilename",
  "mediaFiletype",
  "mediaUrl",
  "membersCount",
  "projectsCount",
  "textsCount",
  "usersCount",
  "createdAt",
  "updatedAt"
];

export type TeamFieldswithOrganizationId = TeamInput & {
  organizationId?: string | null;
};

export interface TeamFormProps extends GenericFormProps<TeamInput> {
  formType?: string;
  organizationId?: number | null;
}

const TeamForm = (props: TeamFormProps) => {
  const values = props.values;
  const isEdit = !!(values && values.id);

  const alert = useDispatchGrowlContext();
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  const refForm = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  return (
    <FormBuilder
      ref={refForm}
      autosave={isEdit}
      values={{ ...values }}
      type="dialogForm"
      // prettier-ignore
      // eslint-disable-next-line
      onSubmit={async ({...attr}, { setError }) => {
        alert({
          type: ADD,
          payload: {
            id: idForAlert,
            title: isEdit ? "Saving team..." : "Creating team...",
            variant: "progress",
            indeterminate: true
          }
        });
        if (isEdit) {
          props.mutation({ variables: { id: attr.id, attributes: attr }}).then(({ data } : UpdateTeamMutationResult) => {
            alert({
              type: REMOVE,
              payload: {
                id: idForAlert,
                title: "",
                variant: "success"
              }
            }); 
            if (data?.updateTeam?.errors) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              showErrorMessage(data?.updateTeam.errors, setError);
            }
          });
        } else {
          props.mutation({ variables: { attributes: attr } }).then(({ data } : CreateTeamMutationResult) => {
            alert({
              type: REMOVE,
              payload: {
                id: idForAlert,
                title: "",
                variant: "success"
              }
            });
            if (data?.createTeam?.errors) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              showErrorMessage(data?.createTeam.errors, setError);
            }
          });
        }
      }}
    >
      <TeamFormFields />
      {!isEdit && (
        <ButtonGroup>
          <DiscardFormModal resource="Team">
            <Button variant={"secondary"}> Discard </Button>
          </DiscardFormModal>
          <Button onClick={onSubmit}>Done</Button>
        </ButtonGroup>
      )}
    </FormBuilder>
  );
};

export default TeamForm;
