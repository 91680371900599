// react modules
import React, { useState, useRef } from "react";

// third-party modules
import { Button, FormBuilder } from "@onehq/anton";
import {
  ADD,
  REMOVE,
  REMOVE_ALL,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  CreatePhoneMutationResult,
  UpdatePhoneMutationResult,
  PhoneInput
} from "../../generated/graphql";
import { GenericFormProps } from "../../types";
import { showErrorMessage } from "../../utils/notifications";
import PhoneGeneralForm from "../../pages/phones/sections/PhoneGeneralForm";
import ButtonGroup from "../Form/ButtonGroup";
import { DiscardFormModal } from "../../contexts/DiscardFormModal";

interface NestedPhoneInput extends PhoneInput {
  nestedClientId?: PhoneInput["clientId"];
  nestedCampaignId?: PhoneInput["campaignId"];
}
export interface PhoneFormProps extends GenericFormProps<NestedPhoneInput> {
  formType?: string;
  onSave?: () => void;
  onDiscard?: () => void;
}

const PhoneForm = (props: PhoneFormProps) => {
  const alert = useDispatchGrowlContext();
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  const values = props.values;
  const refForm = useRef<HTMLFormElement>(null);
  const isEdit = !!(values && values.id);
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  return (
    <FormBuilder
      ref={refForm}
      autosave={isEdit}
      values={{ ...values }}
      type="dialogForm"
      // @ts-ignore
      onSubmit={(
        { nestedClientId, nestedCampaignId, ...attr },
        { setError }
      ) => {
        // this form is nested
        attr.clientId = nestedClientId || attr.clientId;
        attr.campaignId = nestedCampaignId || attr.campaignId;

        attr.number = attr.number ? attr.number.replace(/[-()\s_]/g, "") : "";

        alert({
          type: REMOVE_ALL,
          payload: {
            id: idForAlert,
            title: "",
            variant: "progress"
          }
        });

        if (attr.number.length === 0 || attr.number.length < 10) {
          showErrorMessage({ number: "Number should be 10 digits" }, setError);
        } else {
          alert({
            type: ADD,
            payload: {
              id: idForAlert,
              title: isEdit ? "Saving phone..." : "Creating phone...",
              variant: "progress",
              indeterminate: true
            }
          });

          if (isEdit) {
            props
              .mutation({ variables: { id: attr.id, attributes: attr } })
              .then(({ data }: UpdatePhoneMutationResult) => {
                alert({
                  type: REMOVE,
                  payload: {
                    id: idForAlert,
                    title: "",
                    variant: "success"
                  }
                });

                if (Object.keys(data?.updatePhone?.errors || {}).length !== 0) {
                  showErrorMessage(data?.updatePhone?.errors || {}, setError);
                } else {
                  props.onSave && props.onSave();
                }
              });
          } else {
            props
              .mutation({ variables: { attributes: attr } })
              .then(({ data }: CreatePhoneMutationResult) => {
                alert({
                  type: REMOVE,
                  payload: {
                    id: idForAlert,
                    title: "",
                    variant: "success"
                  }
                });
                if (Object.keys(data?.createPhone?.errors || {}).length !== 0) {
                  showErrorMessage(data?.createPhone?.errors || {}, setError);
                } else {
                  props.onSave && props.onSave();
                }
              });
          }
        }
      }}
    >
      <PhoneGeneralForm nested />
      {!isEdit && (
        <ButtonGroup>
          <DiscardFormModal resource="Phone" onDiscard={props.onDiscard}>
            <Button variant={"secondary"}> Discard </Button>
          </DiscardFormModal>
          <Button onClick={onSubmit}>Save</Button>
        </ButtonGroup>
      )}
    </FormBuilder>
  );
};

export default PhoneForm;
