/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// third-party modules
import {
  Fieldset,
  FloatContext,
  FloatFormProps,
  HiddenField,
  NestedForm,
  SearchField,
  SelectField,
  TextField,
  useGetValue,
  useSetFieldValue,
  useWatchContext
} from "@onehq/anton";

// app modules
import {
  ClientQueryFilterFields,
  FilterOperation,
  RestrictionOperation,
  TeamQueryFilterFields,
  useCustomCurrentUserQuery,
  useGetClientsListLazyQuery,
  useGetTeamsListLazyQuery,
  UserType
} from "../../../generated/graphql";
import { DEFAULT_LIMIT, USERS_PATH } from "../../../constants";
import { SelectFieldOptionType } from "../../../types";
import {
  addSpacesBetweenWords,
  formatClientList,
  formatTeamList,
  isAllowed,
  notificationChannelOptions
} from "../../../utils";
import UserPhonesForm from "./UserPhonesForm";

export const UserGeneralFormOmitValues = [
  "client",
  "clientOrganizationId",
  "currentOrganization",
  "currentRole",
  "hasRefreshToken",
  "mediaFilename",
  "mediaFiletype",
  "mediaUrl",
  "organizations",
  "projects",
  "projectsCount",
  "searchClientId",
  "searchTeamId",
  "team",
  "texts",
  "textsCount",
  "userType",
  "__typename"
];

const UserGeneralForm = () => {
  const location = useLocation();

  // state variables for search inputs
  const [defaultTeamOptions, setDefaultTeamOptions] = useState<
    Array<SelectFieldOptionType>
  >([]);
  const [defaultClientOptions, setDefaultClientOptions] = useState<
    Array<SelectFieldOptionType>
  >([]);
  // state variable for userType select input
  const [userTypeOptions, setUserTypeOptions] = useState<any[]>([]);

  // fetch current user data
  const { data: currentUser } = useCustomCurrentUserQuery({
    fetchPolicy: "cache-and-network"
  });
  // lazy queries for search inputs
  const [teamsQuery] = useGetTeamsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [clientsQuery] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const hasTeam = useWatchContext("searchTeamId");
  const userTypeInput = useWatchContext("userType");
  const setFieldValue = useSetFieldValue();
  const getValue = useGetValue();

  // constants
  const userTypeValidation = userTypeInput === "Client";
  const isEdit = location.pathname !== `/${USERS_PATH}/new`;

  // fetch clients and teams for search inputs on first render
  useEffect(() => {
    clientsQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
    })
      // TODO: remove
      // @ts-ignore
      .then(response => {
        setDefaultClientOptions(
          formatClientList(response.data?.clients?.nodes || [])
        );
      })
      .catch(err => {
        console.log(err);
      });
    teamsQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
    })
      // TODO: remove
      // @ts-ignore
      .then(response => {
        setDefaultTeamOptions(
          formatTeamList(response.data?.teams?.nodes || [])
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const canViewUser = isAllowed(
      currentUser,
      "User",
      RestrictionOperation.View
    );

    const canViewClient = isAllowed(
      currentUser,
      "Client",
      RestrictionOperation.View
    );

    const createOption = (value: string) => ({
      label: addSpacesBetweenWords(value),
      value
    });

    let tempUserTypes: any[] = [];

    if (isEdit) {
      tempUserTypes = Object.keys(UserType).map(item => createOption(item));
    } else if (canViewUser && canViewClient) {
      tempUserTypes = Object.keys(UserType)
        .filter(el => el === UserType.Client)
        .map(item => createOption(item));
    } else {
      tempUserTypes = [createOption(UserType.Texter)];
    }

    setUserTypeOptions(tempUserTypes);
  }, [currentUser]);

  useEffect(() => {
    if (userTypeValidation && hasTeam) setFieldValue("searchTeamId", null);
    else setFieldValue("searchClientId", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeValidation]);

  /* ########## Logic for Floating form ########## */
  // state variables
  const [submitForm, setSubmitForm] = useState(false);
  const [searchFieldInputs, setSearchFieldInputs] = useState({
    clientId: null,
    teamId: null
  });

  // sets client in the form when creation is done
  useEffect(() => {
    if (submitForm && searchFieldInputs.clientId) {
      setFieldValue("searchClientId", searchFieldInputs.clientId);
      setSubmitForm(false);
    }
  }, [searchFieldInputs.clientId]);

  // sets team in the form when creation is done
  useEffect(() => {
    if (submitForm && searchFieldInputs.teamId) {
      setFieldValue("searchTeamId", searchFieldInputs.teamId);
      setSubmitForm(false);
    }
  }, [searchFieldInputs.teamId]);

  // can this user...
  const canCreateClients = isAllowed(
    currentUser,
    "Client",
    RestrictionOperation.Create
  );
  const canCreateTeams = isAllowed(
    currentUser,
    "Team",
    RestrictionOperation.Create
  );

  const { add } = useContext(FloatContext);

  const floatFormProps: FloatFormProps = useMemo(() => {
    return {
      searchFieldInputs,
      setSearchFieldInputs,
      setSubmitForm,
      variant: ""
    };
  }, [setSubmitForm]);

  // onAddNew events for searchFiels
  const onAddNewClient = () => {
    floatFormProps.variant = "Client";
    add(floatFormProps);
  };

  const onAddNewTeam = () => {
    floatFormProps.variant = "Team";
    add(floatFormProps);
  };
  /* #################### */

  return (
    <Fieldset>
      <TextField label="Name" name="name" required />
      <TextField label="Email" name="email" required />
      {/* @ts-ignore */}
      <SelectField
        label="Type"
        name="userType"
        options={userTypeOptions}
        disabled={isEdit}
        required
      />
      <HiddenField name="clientOrganizationId" />
      {!isEdit && (
        <>
          <TextField
            type="password"
            label="Password"
            name="password"
            required
          />
          <TextField
            type="password"
            label="Password Verify"
            name="passwordVerify"
            required
            validate={(value: any) => {
              return value === getValue("password")
                ? undefined
                : "Passwords must match";
            }}
          />
        </>
      )}
      {userTypeValidation && (
        <>
          {/* @ts-ignore */}
          <SearchField
            label="Client"
            name="searchClientId"
            defaultOptions={defaultClientOptions}
            required
            onChange={(value: any) =>
              setFieldValue("clientOrganizationId", value.organizationId)
            }
            loadOptions={(text: string) =>
              clientsQuery({
                variables: {
                  filters: [
                    {
                      field: ClientQueryFilterFields.Name,
                      operation: FilterOperation.Like,
                      value: text
                    }
                  ]
                }
                // TODO: remove
                // @ts-ignore
              }).then(response =>
                formatClientList(response.data?.clients?.nodes || [])
              )
            }
            onAddNew={canCreateClients ? () => onAddNewClient() : undefined}
          />
        </>
      )}
      {!userTypeValidation && (
        <>
          {/* @ts-ignore */}
          <SearchField
            label="Team"
            name="searchTeamId"
            defaultOptions={defaultTeamOptions}
            required
            loadOptions={(text: string) =>
              teamsQuery({
                variables: {
                  filters: [
                    {
                      field: TeamQueryFilterFields.Name,
                      operation: FilterOperation.Like,
                      value: text
                    }
                  ]
                }
                // TODO: remove
                // @ts-ignore
              }).then(response =>
                formatTeamList(response.data?.teams?.nodes || [])
              )
            }
            onAddNew={canCreateTeams ? () => onAddNewTeam() : undefined}
          />
        </>
      )}
      <Fieldset legend={"Notifications"}>
        {/* @ts-ignore */}
        <SelectField
          label="Notification Channel"
          name="notificationChannel"
          options={notificationChannelOptions}
        />
      </Fieldset>
      <NestedForm
        legend={"Phones"}
        name="phones"
        component={UserPhonesForm}
        condensed
      />
    </Fieldset>
  );
};

export default UserGeneralForm;
