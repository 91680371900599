// react modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  IconNames
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  DestroyCollectionMutation,
  useCustomCurrentUserQuery,
  useDestroyCollectionMutation,
  useGetCollectionQuery
} from "../../../generated/graphql";
import { ORGANIZATIONS_PATH } from "../../../constants";
import sections from "../sections";
import { formatDate } from "../../../utils";

export interface CollectionAnchorProps {
  id: string;
  route: ResourceRoute;
}

const CollectionAnchor = ({ id }: CollectionAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();
  const { data: currentUser } = useCustomCurrentUserQuery();

  const organizationId = currentUser?.currentUser.currentOrganization.id;

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [destroyCollectionMutation] = useDestroyCollectionMutation({
    onCompleted: (response: DestroyCollectionMutation) => {
      if (Object.keys(response?.destroyCollection?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The collection has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${ORGANIZATIONS_PATH}/${organizationId}/collections`);
      }
    },
    onError: err => {
      console.error(err.message); // the error if that is the case
    }
  });

  const { data, loading } = useGetCollectionQuery({
    variables: { id }
  });

  const collection = data?.collection;
  if (loading || !collection) return <BaseResource.Anchor skeleton />;

  const deleteCollection = async () => {
    await destroyCollectionMutation({ variables: { id: collection.id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        {
          label: "Collection Type",
          value: collection?.collectionType
        },
        {
          label: "Amount",
          value: collection?.amount
        },
        {
          label: "Fee",
          value: collection?.fee
        },
        {
          label: "Collection Date",
          value: formatDate(collection?.collectionDate)
        }
      ]}
    />
  );

  const menuAnchorProps = {
    cards: [infoCard],
    editLinks: sections,
    kebabMenuItems,
    name: `${collection?.collectionType} - ${collection?.amount}`,
    subItem: "Collection",
    type: collection?.collectionType,
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "dollarSign" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this Collection?"
        title="Delete Collection?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteCollection}
      />
    </>
  );
};

export default CollectionAnchor;
