// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  DateField,
  Fieldset,
  TextField,
  useWatchContext,
  useSetFieldValue,
  SelectField
} from "@onehq/anton";

// app modules
import {
  BrandQueryFilterFields,
  CampaignType,
  FilterOperation,
  Provider,
  useAdminUserEmailsQuery,
  useCustomCurrentUserQuery,
  useGetBrandsListLazyQuery,
  useGetClientsListQuery
} from "../../../generated/graphql";
import { addSpacesBetweenWords, formatClientList } from "../../../utils";
import { SelectFieldOptionType as Option } from "../../../types";
import { useParams } from "react-router-dom";
import CampaignPhonesForm from "./CampaignPhonesForm";

export const CampaignGeneralFormOmitValues = [
  "__typename",
  "client",
  "brand",
  "organizationId",
  "previous",
  "canBuyMorePhones",
  "phonesAttributes",
  "phones"
];

export const providerOptions =
  Object.keys(Provider).map(item => ({
    label: addSpacesBetweenWords(item),
    value: item
  })) || [];

export const campaignTypeOptions = Object.values(CampaignType).map(ct => {
  return { label: ct, value: ct };
});

interface CampaignGeneralFormProps {
  // if this form is being used in a nested form, we should set this prop to true
  // in order to avoid having duplicated input names (i.e. project form and campaign form in a floating form both have clientId)
  nested?: boolean;
}

const CampaignGeneralForm = ({ nested }: CampaignGeneralFormProps) => {
  const clientIdName = nested ? "nestedClientId" : "clientId";

  const [brandOptions, setBrandOptions] = useState<Option[]>([]);
  const clientId = useWatchContext(clientIdName);
  const client = useWatchContext("client");
  const type = useWatchContext("campaignType");
  const isRegistered = useMemo(() => type === CampaignType.Registered, [type]);

  const setValue = useSetFieldValue();

  // is edition or creation
  const params = useParams();
  const isEdit = !!params.id;

  // lazy queries
  const { data: clientsData } = useGetClientsListQuery({
    fetchPolicy: "cache-and-network"
  });
  const [getBrands] = useGetBrandsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const { data: adminUserEmails } = useAdminUserEmailsQuery();
  const { data: currentUser } = useCustomCurrentUserQuery();

  const currentUserEmail = useMemo(
    () => currentUser?.currentUser.email,
    [currentUser]
  );

  const isUserAdmin = useMemo(() => {
    if (!adminUserEmails || !currentUserEmail) {
      return false; // Return false if either list of admin emails or current user email is not available
    }
    return adminUserEmails.adminUserEmails.includes(currentUserEmail);
  }, [adminUserEmails, currentUserEmail]);

  const clientsOptions = useMemo(() => {
    const nodes = [...(clientsData?.clients.nodes || [])];
    nodes.sort((a, b) => {
      if (a && b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else return 1;
    });
    return formatClientList(nodes);
  }, [clientsData]);

  useEffect(() => {
    getBrands({
      variables: {
        filters: [
          {
            field: BrandQueryFilterFields.ClientId,
            operation: isRegistered
              ? FilterOperation.Equal
              : FilterOperation.With,
            value: isRegistered ? clientId : "false"
          }
        ]
      }
    })
      .then(response => {
        setBrandOptions(
          (response.data?.brands?.nodes || []).map(p => ({
            label: p?.name,
            value: p?.id
          })) as Option[]
        );
      })
      .catch(errors => console.log(errors));
  }, [clientId, isRegistered, getBrands]);

  useEffect(() => {
    if (type === CampaignType.Unregistered) setValue("clientId", null);
  }, [type, setValue]);

  useEffect(() => {
    if (!clientId && client?.id) setValue("brandId", null);
  }, [client, clientId, setValue]);

  return (
    <Fieldset>
      <TextField label="Name" name="name" required />
      {isUserAdmin && (
        <>
          {/* @ts-ignore */}
          <SelectField
            label="Type"
            name="campaignType"
            options={campaignTypeOptions}
            required
          />
        </>
      )}
      {isRegistered && (
        <>
          {/* @ts-ignore */}
          <SelectField
            label="Client"
            name={clientIdName}
            required
            options={clientsOptions}
          />
        </>
      )}
      {/* @ts-ignore */}
      <SelectField
        label="Brand"
        name="brandId"
        options={brandOptions}
        required
      />
      <TextField label="Code" name="code" required />
      <DateField label="Start Date" name="startDate" />
      <DateField label="End Date" name="endDate" />
      {isEdit && (
        <Fieldset legend="Purchased Phones">
          <CampaignPhonesForm />
        </Fieldset>
      )}
    </Fieldset>
  );
};

export default CampaignGeneralForm;
