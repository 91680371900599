import moment from "moment";

export const roundingNumber = function (n: number) {
  return Math.round(n * 10) / 10;
};

export const addSpacesBetweenWords = function (text: any) {
  // this works adding a space before every capital letter, so it will work for camel case strings
  return text?.replace(/[A-Z]/g, " $&").trim();
};

// same as `addSpacesBetweenWords`, but taking 2+ uppercases together as an acronym
export const addSpacesBetweenWordsWithAcronyms = (text = "") => {
  return (
    text
      // add a space before the start of a word (1 uppercase + 1+ lowercase)
      .replaceAll(/([A-Z]{1}[a-z]{1,})/g, " $1")
      // add a space after the end of a word (1+ lowercase)
      .replaceAll(/([a-z]{1,})/g, "$1 ")
      // some cases will add at the end of a word and at the start of the next, remove dup space
      .replaceAll(/\s\s/g, " ")
      .trim()
  );
};

export const removeSpacesBetweenWords = function (text: any) {
  return text?.replace(/ +/g, "");
};

export const formatDate = function (date: any) {
  return date && moment(date as string).format("M/D/YYYY hh:mm a");
};

export const formatPhone = (phone: string) => {
  if (!phone) {
    return "";
  }

  let formattedPhone = phone.replace(/\D/g, "");
  if (formattedPhone.length > 10) {
    formattedPhone = formattedPhone.slice(-10); // last 10 numbers
  } else if (formattedPhone.length < 10) {
    formattedPhone = formattedPhone.padStart(10, "0"); // fill with 0s at the start to get 10 numbers
  }
  return `(${formattedPhone.slice(0, 3)})-${formattedPhone.slice(
    3,
    6
  )}-${formattedPhone.slice(6, 10)}`;
};

export const optionNameFromId = (optionId: string | null | undefined) => {
  return optionId?.substring(optionId.lastIndexOf(":") + 1);
};

export const groupByObjectProperty = (objectArray: any[], property: string) => {
  return objectArray.reduce((accumulator, obj) => {
    const key = obj[property];
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(obj);
    return accumulator;
  }, {});
};

export const formatNumber = (n: number) => {
  // formatting number with comma separated thousands, millions, etc...
  return n?.toLocaleString("en-US");
};

export const PAGE_SIZE = 50;
export const MAX_PAGE_SIZE = 250;
export const READYGOP_ORGS = [1, 4];
