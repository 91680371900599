import React from "react";
import ReactDOM from "react-dom/client";
import { FloatProvider } from "@onehq/anton";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ResourceTabsProvider } from "./providers/resource_tabs_provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // comment StrictMode
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <ResourceTabsProvider>
      <FloatProvider>
        <App />
      </FloatProvider>
    </ResourceTabsProvider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
