import React, { useMemo, useRef, useState } from "react";

import styled from "styled-components";
import { FormBuilder } from "@onehq/anton";

import {
  BasePhoneFieldsFragment,
  BaseTextFieldsFragment,
  LoadSendProjectDocument,
  LoadSendProjectOption,
  MediaPosition,
  PhoneStatus,
  ProjectFieldsFragment,
  ProviderGroup
} from "../../generated/graphql";
import TestTextFormFields from "../projects/ProjectAnchor/TestTextFormFields";
import { addErrorAlert, formatPhone, formatPhoneOption } from "../../utils";
import { htmlToUnicode } from "../../components/Project/utils";
import { useLazyQuery } from "@apollo/client";
import {
  ADD,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";

const HorizontalSections = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

const MaxWidth = styled.div`
  width: 100%;
`;

export interface Phone extends BasePhoneFieldsFragment {
  mediaPosition: ProviderGroup | null;
}

export interface ProjectTestTextsProps {
  message: string;
  projectMediaUrl?: string | null;
  projectMediaPosition?: MediaPosition | null;
  managerPhones?: ProjectFieldsFragment["manager"]["recipientPhones"];
  clientPhones?: ProjectFieldsFragment["client"]["clientRecipientPhones"];
  alreadySelectedPhone?: BaseTextFieldsFragment["toPhone"];
  fromPhones?: Partial<Phone>[];
  projectMessage?: string;
  onSend?: (phoneTo: string[], body: string, fromPhoneId: string) => void;
  clientId?: string;
  project?: ProjectFieldsFragment;
}

const ProjectTestTextStep = ({
  message,
  projectMediaUrl,
  projectMediaPosition,
  alreadySelectedPhone,
  projectMessage,
  clientId,
  project
}: ProjectTestTextsProps) => {
  const refForm = useRef<HTMLFormElement>(null);

  const managerPhones = useMemo(() => {
    return project?.manager?.recipientPhones || [];
  }, [project]);

  const clientPhones = useMemo(() => {
    return project?.client?.clientRecipientPhones || [];
  }, [project]);

  const fromPhones = useMemo(() => {
    const allPhones = project?.projectPhones?.map(p => {
      return {
        ...p.phone,
        mediaPosition: p.campaign.brand.mediaPosition
      };
    });
    return Array.from(new Set(allPhones));
  }, [project]);

  const [sendTextQuery] = useLazyQuery(LoadSendProjectDocument);

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const sendText = (phoneTo: string[], body = "", fromPhoneId?: string) => {
    if (!project || !project.id) {
      addErrorAlert(
        alert,
        "Error, text not sent",
        "Project is undefined or missing an ID."
      );
      return;
    }
    void sendTextQuery({
      fetchPolicy: "no-cache",
      variables: {
        projectId: project.id,
        option: LoadSendProjectOption.TestText,
        phoneIds: phoneTo,
        body,
        fromPhoneId
      },
      onCompleted: response => {
        const errors =
          (response?.loadSendProject?.errors as string[] | undefined) || [];
        if (errors?.length > 0) {
          addErrorAlert(alert, "Error, text not sent", errors.join(". "));
        } else {
          alert({
            type: ADD,
            payload: {
              title: "All changes saved",
              message: response.loadSendProject.message,
              variant: "success"
            }
          });
        }
      },
      onError: err => {
        addErrorAlert(alert, "Error, text not sent", err.message);
        console.error(err.message);
      }
    });
  };

  const [formValues, setFormValues] = useState<{
    phoneTo: string[];
    message: string | undefined;
    fromPhoneId: string | undefined;
  }>({
    phoneTo: [],
    message: "",
    fromPhoneId: undefined
  });

  const onUpdate = (values: any) => {
    const safeMessage =
      typeof values.message === "string" ? values.message : "";
    setFormValues({
      phoneTo: values.phoneId || [],
      message: htmlToUnicode(safeMessage as string),
      fromPhoneId:
        typeof values.fromPhoneId === "string" ? values.fromPhoneId : undefined
    });
    return Promise.resolve();
  };

  const submit = () => {
    // Validate formValues before sending
    const isPhoneToValid = formValues.phoneTo && formValues.phoneTo.length > 0;
    const isBodyValid = formValues.message && formValues.message.trim() !== "";
    const isFromPhoneIdValid =
      formValues.fromPhoneId && formValues.fromPhoneId.trim() !== "";

    if (isPhoneToValid && isBodyValid && isFromPhoneIdValid) {
      sendText(formValues.phoneTo, formValues.message, formValues.fromPhoneId);
    } else {
      addErrorAlert(
        alert,
        "Error, text not sent",
        "Form is incomplete or contains invalid values, cannot submit."
      );
    }
  };

  const defaultFromPhone = fromPhones?.find(
    p => p.phoneStatus === PhoneStatus.Active
  );

  return (
    <FormBuilder
      ref={refForm}
      values={{
        message,
        mediaUrl: projectMediaUrl,
        mediaPosition: projectMediaPosition,
        fromPhoneId: defaultFromPhone
          ? formatPhoneOption(defaultFromPhone)
          : null,
        phoneId: alreadySelectedPhone
          ? [
              {
                label: formatPhone(alreadySelectedPhone.number),
                value: alreadySelectedPhone.id
              }
            ]
          : null
      }}
      onSubmit={onUpdate}
    >
      <MaxWidth>
        <HorizontalSections>
          <TestTextFormFields
            managerPhones={managerPhones}
            clientPhones={clientPhones}
            alreadySelectedPhone={alreadySelectedPhone}
            projectMessage={projectMessage}
            fromPhones={fromPhones}
            onSubmit={submit}
            clientId={clientId}
          />
        </HorizontalSections>
      </MaxWidth>
    </FormBuilder>
  );
};

export default ProjectTestTextStep;
