// react modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  IconNames
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  DestroyPhoneMutation,
  useDestroyPhoneMutation,
  useGetPhoneQuery,
  ToggleFavoriteMutation,
  useToggleFavoriteMutation,
  useIsFavoriteQuery
} from "../../../generated/graphql";
import { PHONES_PATH } from "../../../constants";
import { formatPhone, phoneStatusColor } from "../../../utils";
import sections from "../sections";

export interface PhoneAnchorProps {
  id: string;
  route: ResourceRoute;
}

const PhoneAnchor = ({ id }: PhoneAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const { data: isFavoriteData } = useIsFavoriteQuery({
    fetchPolicy: "no-cache",
    variables: { phoneId: id }
  });

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    setIsFavorite(isFavoriteData?.isFavorite || false);
  }, [isFavoriteData]);

  const [toggleFavorite] = useToggleFavoriteMutation({
    onCompleted: (response: ToggleFavoriteMutation) => {
      setIsFavorite(response.toggleFavorite?.isFavorite || false);
    }
  });

  const [destroyPhoneMutation] = useDestroyPhoneMutation({
    onCompleted: (response: DestroyPhoneMutation) => {
      if (Object.keys(response?.destroyPhone?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The phone has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${PHONES_PATH}`);
      }
    },
    onError: err => {
      console.error(err.message); // the error if that is the case
    }
  });

  const { data, loading } = useGetPhoneQuery({
    context: {
      headers: {
        isView: "true",
        resource: "Phone"
      }
    },
    variables: { id }
  });
  const phone = data?.phone;
  if (loading || !phone) return <BaseResource.Anchor skeleton />;

  const deletePhone = async () => {
    await destroyPhoneMutation({ variables: { id: phone.id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        {
          label: "Phone",
          value: formatPhone(phone?.number)
        },
        {
          label: "Status",
          value: phone?.phoneStatus
        },
        {
          label: "Type",
          value: phone?.phoneType
        },
        {
          label: "Class",
          value: phone?.phoneClass
        },
        {
          label: "State",
          value: phone?.state?.abbreviation
        },
        {
          label: "Do not contact",
          value: phone?.doNotContact
        },
        {
          label: "Company Number",
          value: phone?.operatingCompanyId
        },
        {
          label: "Carrier",
          value: phone?.carrier
        },
        {
          label: "Network",
          value: phone?.network
        },
        {
          label: "First Name",
          value: phone?.firstName
        },
        {
          label: "Last Name",
          value: phone?.lastName
        }
      ]}
    />
  );

  const phoneStatus = phone?.phoneStatus;

  const menuAnchorProps = {
    cards: [infoCard],
    editLinks: sections,
    kebabMenuItems,
    name: formatPhone(phone?.number),
    statusColor: phoneStatusColor(phoneStatus),
    subItem: "Phone",
    type: phoneStatus,
    onEditPath: "general",
    onViewPath: "overview",
    onFavoriteToggle: () => toggleFavorite({ variables: { phoneId: id } }),
    favorite: isFavorite,
    defaultIcon: (phone?.phoneType === "Mobile"
      ? "smartphone"
      : "phone") as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this Phone?"
        title="Delete Phone?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deletePhone}
      />
    </>
  );
};

export default PhoneAnchor;
