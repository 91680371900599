// react modules
import React from "react";

// third-party modules
import { FloatStyles, useGetValue, useSetFieldValue } from "@onehq/anton";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";

// app modules
import {
  useCreateCampaignMutation,
  useCreateClientMutation,
  useCreateListMutation,
  useCreatePhoneMutation,
  useCreateTeamMutation
} from "../../generated/graphql";
import ClientForm from "../Client/ClientForm";
import PhoneForm from "../Phone/PhoneForm";
import ListForm from "../List/ListForm";
import TeamForm from "../../pages/teams/sections/TeamGeneralForm";
import CampaignForm from "../Campaign/CampaignForm";
import { formatPhoneOption } from "../../utils";

export const FormConfigs = {
  Client: {
    form: ClientForm,
    mutation: useCreateClientMutation
  },
  Phone: {
    form: PhoneForm,
    mutation: useCreatePhoneMutation
  },
  List: {
    form: ListForm,
    mutation: useCreateListMutation
  },
  Team: {
    form: TeamForm,
    mutation: useCreateTeamMutation
  },
  Campaign: {
    form: CampaignForm,
    mutation: useCreateCampaignMutation
  }
};

export interface FloatingFormProps {
  isEdit?: boolean;
  open: boolean;
  onClose: () => void;
  // if we are gonna use attachNewValueToInput, these props are mostly irrelevant
  searchFieldInputs?: any;
  setSearchFieldInputs?: React.Dispatch<React.SetStateAction<any>>;
  setSubmitForm?: Function;
  //
  phoneToAdd?: "phoneId" | "externalPhoneId" | "toPhoneId" | "fromPhoneId";
  variant: "Client" | "Phone" | "List" | "Team" | "Campaign";
  defaultValues?: any; // default values for the form
  showOverlay?: boolean; // the form will be display with an overlay
  // to be able to use this feature, this floatingform must be rendered inside the FormBuilder that contains the searchInput
  attachNewValueToInput?: boolean; // if true, adding a new element fetch the new element in the input (gets selected)
  customFieldName?: string; // ignore standard variantId format (and phone formats) and directly sets the name of the field
  customValueFormatter?: (value: any) => any;
}

const FloatingForm = ({
  isEdit = false,
  onClose,
  searchFieldInputs,
  setSearchFieldInputs,
  setSubmitForm,
  phoneToAdd = "phoneId",
  variant = "Client",
  defaultValues = {},
  attachNewValueToInput = false,
  customFieldName,
  customValueFormatter
}: FloatingFormProps) => {
  const Form = FormConfigs[variant].form;
  const useCreateMutation = FormConfigs[variant].mutation;

  // alerts
  const alert = useDispatchGrowlContext();
  const getValue = useGetValue();
  const setValue = useSetFieldValue();

  // current input name to fetch new value
  const inputName =
    customFieldName ||
    (variant === "Phone" ? phoneToAdd : variant.toLowerCase() + "Id");

  const attachNewElementToInput = (value: any) => {
    // get current value of the input
    const currentInputValue = getValue(inputName);
    // if current input is a multi select, fetch new value into selected elements, otherwise just set new value
    const newInputValue = Array.isArray(currentInputValue)
      ? [...currentInputValue, value]
      : value;
    // set new value
    setValue(inputName, newInputValue);
  };

  const [create] = useCreateMutation({
    onCompleted: (response: any) => {
      const result = response?.[`create${variant}`];
      const newOptionValue = customValueFormatter
        ? customValueFormatter(result.resource)
        : result.resource?.id;

      const hasErrors = Object.keys(result?.errors as Object).length > 0;

      // load the initial input with the new data
      if (!hasErrors && variant === "Phone") {
        const newOption = isEdit
          ? newOptionValue
          : {
              ...formatPhoneOption(result.resource),
              value: newOptionValue
            };

        !!setSearchFieldInputs &&
          setSearchFieldInputs({
            ...searchFieldInputs,
            [inputName]: newOption
          });
        attachNewValueToInput && attachNewElementToInput(newOption);
      } else if (!hasErrors) {
        const newOption = isEdit
          ? newOptionValue
          : {
              label: result.resource?.name,
              value: newOptionValue
            };
        !!setSearchFieldInputs &&
          setSearchFieldInputs({
            ...searchFieldInputs,
            [inputName]: newOption
          });
        attachNewValueToInput && attachNewElementToInput(newOption);
      }

      if (!hasErrors) {
        !!setSubmitForm && setSubmitForm(true);
        onClose();
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `The ${variant} has been created successfully`,
            variant: "success"
          }
        });
      } else {
        const errors = Object.entries(result.errors as Object);
        const message = errors.reduce((previous, [key, value]) => {
          const space = previous ? " " : "";
          return `${previous}${space}${key}: ${value}.`;
        }, "");
        alert({
          type: ADD,
          payload: { title: "Error", message, variant: "error" }
        });
      }
    },
    onError: err => console.error(err)
  });

  return (
    <>
      <FloatStyles.FormWrapper>
        <Form mutation={create} values={defaultValues} onDiscard={onClose} />
      </FloatStyles.FormWrapper>
    </>
  );
};

export default FloatingForm;
