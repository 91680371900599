import React from "react";

import {
  DateField,
  Fieldset,
  NumberField,
  SelectField,
  useWatchContext
} from "@onehq/anton";

import { CollectionType } from "../../../generated/graphql";

const collectionTypes = Object.keys(CollectionType).map(t => ({
  label: t,
  value: t
}));

const CollectionGeneralForm = () => {
  const collectionType = useWatchContext("collectionType");
  return (
    <Fieldset>
      {/* @ts-ignore */}
      <SelectField name="collectionType" options={collectionTypes} required />
      <NumberField name="amount" required />
      {collectionType === CollectionType.Collection && (
        <NumberField name="fee" required />
      )}
      <DateField name="collectionDate" required />
    </Fieldset>
  );
};

export default CollectionGeneralForm;
