import { DotColor } from "@onehq/anton";
import { TextStatus } from "../generated/graphql";

export default function textStatus(status: TextStatus): DotColor {
  switch (status) {
    case TextStatus.Active:
    case TextStatus.Sent:
    case TextStatus.Received:
    case TextStatus.Delivered:
    case TextStatus.Clicked:
      return "pea60" as DotColor;
    case TextStatus.Queued:
    case TextStatus.Sending:
      return "iris80" as DotColor;
    case TextStatus.Ready:
      return "royal50" as DotColor;
    case TextStatus.Error:
    case TextStatus.Failed:
    case TextStatus.Expired:
    case TextStatus.NumberFormat:
    case TextStatus.MediaSize:
    case TextStatus.RateLimited:
    case TextStatus.Spam:
    case TextStatus.Undelivered:
    case TextStatus.UpdateFailed:
    default:
      return "wine50" as DotColor;
  }
}

export function textStatusBadgeColor(status: TextStatus): DotColor {
  switch (status) {
    case TextStatus.Active:
    case TextStatus.Sent:
    case TextStatus.Received:
    case TextStatus.Delivered:
    case TextStatus.Clicked:
      return "green" as DotColor;
    case TextStatus.Ready:
      return "blue" as DotColor;
    case TextStatus.Queued:
    case TextStatus.Sending:
    case TextStatus.Early:
      return "purple" as DotColor;
    case TextStatus.Error:
    case TextStatus.Failed:
    case TextStatus.Undelivered:
    case TextStatus.UpdateFailed:
    case TextStatus.RateLimited:
    case TextStatus.NumberFormat:
    case TextStatus.MediaSize:
    case TextStatus.Rejected:
    case TextStatus.Spam:
    default:
      return "red" as DotColor;
  }
}
