import { useState } from "react";
import { DocumentNode, useApolloClient } from "@apollo/client";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";
import { ButtonProps } from "@onehq/anton";
import { toInteger } from "lodash";
import { ExportCsvQueryDocument } from "../generated/graphql";
import { MAX_PAGE_SIZE } from "../utils";

interface Variables {
  [key: string]: any;
}

export interface GenerateCsvProps {
  apolloDocument: DocumentNode;
  variables?: Variables;
  disable?: boolean;
}

export const useGenerateCsv = ({
  apolloDocument,
  variables,
  disable
}: GenerateCsvProps) => {
  const [alertId] = useState(toInteger(Math.random() * 1000));
  const alert = useDispatchGrowlContext();

  const queryString = apolloDocument?.loc?.source?.body;

  // client solution for apollo graphql useLazyQuery re-render bug
  // https://github.com/apollographql/apollo-client/issues/5912
  const client = useApolloClient();
  const onClickLinkCsv = (newVariables?: Variables) => {
    alert({
      type: ADD,
      payload: {
        id: alertId,
        title: "Generating CSV...",
        variant: "progress",
        indeterminate: true
      }
    });
    const finalVariables = {
      ...variables,
      ...newVariables,
      first: MAX_PAGE_SIZE
    };
    client
      .query({
        query: ExportCsvQueryDocument,
        fetchPolicy: "no-cache",
        variables: {
          query: queryString,
          variables: finalVariables
        }
      })
      .then((response: any) => {
        const message = response.data.exportCsv.message as string;
        if (message) {
          alert({
            type: ADD,
            payload: {
              title: message,
              variant: "success"
            }
          });
        } else {
          alert({
            type: ADD,
            payload: {
              title: "CSV generation failed",
              variant: "error"
            }
          });
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        alert({
          type: REMOVE,
          payload: { id: alertId, title: "", variant: "success" }
        });
      });
  };

  const csvButtonProps: ButtonProps<any> = {
    disabled: disable || false,
    variant: "secondary",
    icon: "externalLink",
    iconPosition: "leading",
    onClick: onClickLinkCsv
  };

  return {
    csvButtonProps
  };
};
