import React, { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ADD, Route, useDispatchGrowlContext } from "@onehq/framework";

import {
  LoginRoute,
  LogoutRoute,
  MainLoginRoute,
  PasswordRecoveryRoute,
  PasswordResetRoute,
  SignUpRoute,
  UpdatePasswordRoute
} from "./pages/authentication";
import { CampaignsRoute } from "./pages/campaigns";
import { BrandsRoute } from "./pages/brands";
import { BillingReportRoute } from "./pages/billingReport";
import { ClientsRoute } from "./pages/clients";
import DashboardRoute, { BaseRoute } from "./pages/dashboard";
import HomepageRoute from "./pages/homepage";
import { ListsRoute } from "./pages/lists";
import { OrganizationsRoute } from "./pages/organization";

import { PhonesRoute } from "./pages/phones";
import PrivacyRoute from "./pages/privacy";
import { ProjectsRoute } from "./pages/projects";
import { ShortenedLinksRoute } from "./pages/shortenedLinks";
import { StepperRoute, StepperCreatedRoute } from "./pages/stepper";
import { TeamsRoute } from "./pages/teams";
import { TextsRoute } from "./pages/texts";
import { UsersRoute } from "./pages/users";
import TermsRoute from "./pages/terms";
import {
  optInPagesRoutes,
  optInPrivacyRoutes,
  optInTermsRoutes
} from "./pages/opts"; // TODO: remove
import { AuditReportRoute } from "./pages/auditReport";
import { CollectionsRoute } from "./pages/collections";

// TODO: remove
export const GoBack = () => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  useEffect(() => {
    alert({
      type: ADD,
      payload: {
        id: Math.random() * 10000 + 1,
        title: "Page not found",
        message: "We redirected you to the previous page",
        variant: "error"
      }
    });
    if (window.history.state && window.history.state.idx > 0) {
      // if there is previous page for readygop
      navigateTo(-1);
    } else {
      navigateTo("/dashboard", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

// TODO: remove
export const WindowSizeContext = createContext<any>({ isMobile: false });

export const PrivateRoutes = {
  // add routes here:
  AuditReport: AuditReportRoute,
  Base: BaseRoute,
  BillingReport: BillingReportRoute,
  Brands: BrandsRoute,
  Campaigns: CampaignsRoute,
  Clients: ClientsRoute,
  StepperCreated: StepperCreatedRoute,
  Dashboard: DashboardRoute,
  Homepage: HomepageRoute,
  Lists: ListsRoute,
  Logout: LogoutRoute,
  Organization: OrganizationsRoute,
  Phones: PhonesRoute,
  Projects: ProjectsRoute,
  ShortenedLinks: ShortenedLinksRoute,
  Stepper: StepperRoute,
  Teams: TeamsRoute,
  Texts: TextsRoute,
  Collections: CollectionsRoute,
  Users: UsersRoute
};

export const PublicRoutes = {
  // add routes here:
  MainLogin: MainLoginRoute,
  HomePage: HomepageRoute,
  Login: LoginRoute,
  Logout: LogoutRoute,
  // opt-in pages dynamic routes here
  optInPagesRoutes,
  optInPrivacyRoutes,
  optInTermsRoutes,
  PasswordRecovery: PasswordRecoveryRoute,
  PasswordReset: PasswordResetRoute,
  Privacy: PrivacyRoute,
  SignUp: SignUpRoute,
  Terms: TermsRoute,
  UpdatePassword: UpdatePasswordRoute
};

const formatRoutes = (routes: { [key: string]: Route }) =>
  Object.keys(routes).map(key => routes[key as keyof typeof routes]);

export const privateRoutes = formatRoutes(PrivateRoutes);
export const publicRoutes = formatRoutes(PublicRoutes);
