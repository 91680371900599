// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  Fieldset,
  PhoneField,
  SelectField,
  TextField,
  useWatchContext
} from "@onehq/anton";

// app modules
import {
  OptionFieldsFragment,
  useGetPhoneStatusOptionsQuery,
  useGetPhoneTypeOptionsQuery,
  PhoneStatusGroup,
  PhoneTypeGroup,
  useGetClientsListQuery,
  PhoneType,
  useGetCampaignsListLazyQuery,
  useCustomCurrentUserQuery
} from "../../../generated/graphql";
import {
  formatCampaignList,
  getGroupedSelectOptions,
  GroupedSelectOptions
} from "../../../utils";
import { SelectFieldOptionType as Option } from "../../../types";
import { READYGOP_ORGS } from "../../../utils/helperFunctions";

export const PhoneGeneralFormOmitValues = ["state", "__typename"];

interface PhoneGeneralFormProps {
  // if this form is being used in a nested form, we should set this prop to true
  // in order to avoid having duplicated input names inside same formBuilder
  nested?: boolean;
}

const PhoneGeneralForm = ({ nested }: PhoneGeneralFormProps) => {
  const [campaigns, setCampaigns] = useState<Option[]>([]);

  const clientsData = useGetClientsListQuery();
  const clientsOptions =
    clientsData.data?.clients.nodes?.map(item => ({
      label: item?.name,
      value: item?.id
    })) || [];

  // queries
  const { data: phoneStatusList } = useGetPhoneStatusOptionsQuery(); // phones status from api
  const { data: phoneTypeList } = useGetPhoneTypeOptionsQuery(); // phone types from api
  const [getCampaigns] = useGetCampaignsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  // check is new record
  const id = useWatchContext("id");
  const isEdit = useMemo(() => {
    return id !== null;
  }, [id]);

  // current user
  const { data: currentUser } = useCustomCurrentUserQuery();

  // check user belongs to ReadyGOP org (1 or 4)
  const currentOrganizations = currentUser?.currentUser?.organizations;
  const isReadyGOPUser = useMemo(() => {
    return currentOrganizations
      ?.map(currentOrganization => {
        return currentOrganization.id;
      })
      .some(organizationId => READYGOP_ORGS.includes(organizationId));
  }, [currentOrganizations]);

  // render extra fields base on phone type
  const phoneType: string | undefined = useWatchContext("phoneType");
  const isClientPhone = phoneType === PhoneType.Client;
  const isProviderPhone = useMemo(() => {
    if (!phoneType) return false;
    const providers = phoneTypeList?.options.nodes?.filter(p =>
      p?.groupId?.includes(PhoneTypeGroup.Provider)
    );
    return providers?.some(p => p && p.name.includes(phoneType));
  }, [phoneType, phoneTypeList]);

  useEffect(() => {
    if (!isProviderPhone) return;
    getCampaigns({})
      .then(response => {
        const allCampaigns = response.data?.campaigns?.nodes || [];
        const filtered = allCampaigns.filter(c => {
          return c?.brand.provider === phoneType;
        });
        setCampaigns(formatCampaignList(filtered));
      })
      .catch(errors => console.log(errors));
  }, [phoneType, isProviderPhone, getCampaigns]);

  const phoneStatusSelect: Array<GroupedSelectOptions> =
    getGroupedSelectOptions(
      (phoneStatusList?.options.nodes as OptionFieldsFragment[]) || [],
      PhoneStatusGroup
    );
  const phoneTypeSelect: Array<GroupedSelectOptions> = getGroupedSelectOptions(
    (phoneTypeList?.options.nodes as OptionFieldsFragment[]) || [],
    PhoneTypeGroup
  );

  return (
    <>
      <Fieldset>
        <PhoneField
          label="Number"
          name="number"
          required
          validate={(value?: string) => {
            if (!value) return undefined;
            const num = value.replace(/[-()\s_]/g, "");
            return num.length < 10 ? "Number should be 10 digits" : undefined;
          }}
        />
        {/* @ts-ignore */}
        <SelectField
          label="Status"
          name="phoneStatus"
          options={phoneStatusSelect}
          required
          disabled={(isEdit && !isReadyGOPUser) || nested}
        />
        <TextField label="Description" name="description" />
        {/* @ts-ignore */}
        <SelectField
          label="Type"
          name="phoneType"
          options={phoneTypeSelect}
          required
          disabled={(isEdit && !isReadyGOPUser) || nested}
        />
        {isClientPhone && (
          <>
            {/* @ts-ignore */}
            <SelectField
              label="Client"
              name={nested ? "nestedClientId" : "clientId"}
              options={clientsOptions}
              required
            />
          </>
        )}
        {isProviderPhone && (
          <>
            {/* @ts-ignore */}
            <SelectField
              label="Campaign"
              name={nested ? "nestedCampaignId" : "campaignId"}
              options={campaigns}
            />
          </>
        )}
        <TextField label="First Name" name="firstName" />
        <TextField label="Last Name" name="lastName" />
      </Fieldset>
    </>
  );
};

export default PhoneGeneralForm;
